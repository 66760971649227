import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout.js"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react";
import Note from '../components/note'
import Example from '../components/example'
import RelatedTerm from '../components/relatedTerm'
import Explication from '../components/explication'
import Line from '../components/line'
import GoBack from "../components/go-back.js";
import {small} from "../components/layout.module.css"


const shortcodes = { Note, Example, RelatedTerm, Explication, Line };

export default function EntryTemplate({data}) {
    const {title, partOfSpeech, chapterTitle, partOfSpeechSlug} = data.mdx.frontmatter
    const {body} = data.mdx

    return (
    <Layout pageTitle={title}>
        <h3> {partOfSpeech} <Link to={"/" + partOfSpeechSlug} className={small}>(see more)</Link></h3> 
        <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
        <br/><br/><br/><GoBack previous={"/" + chapterTitle} title={chapterTitle}></GoBack><br/><br/><br/>
        </Layout>
  )
}

export const query = graphql`
query EntryInfo($slug: String) {
    mdx (frontmatter: {slug: {eq: $slug}}) {
        body
        frontmatter {
          sectionTitle
          title
          chapterTitle
          partOfSpeech
          partOfSpeechSlug
          context
        }
      }
}
`