import React from "react";
import { Link } from "gatsby"
import { goBack } from '../components/layout.module.css'


const GoBack = ({previous, title}) => {
  return (
    <Link to={previous} className={goBack}>
      Back to {title}
    </Link>
  )
}

export default GoBack